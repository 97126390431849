import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import { LiveHelp } from '@mui/icons-material';
import LiveHelpSalesReps from './LiveHelpSalesReps';

const LiveHelpButton = (props) => {
  const [showSalesReps, setShowSalesReps] = useState({
    open: false,
    type: null
  });
  const handleClick = () => {
    setShowSalesReps({
      open: true,
      type: props?.type
    });
  };

  return (
      <>
          <Box
              onClick={handleClick}
              role="presentation"
              sx={props?.style ? { ...props?.style } : { position: 'fixed', bottom: 16, left: 16, zIndex: 1000 }}
          >
              <Fab
                  aria-label="live-help"
                  size={props?.isMobile ? 'medium' : 'large'}
                  sx={{
                    backgroundColor: '#666',
                    '&:hover': {
                      backgroundColor: 'gray',
                    }
                  }}>
                  <LiveHelp sx={{ fontSize: props?.isMobile ? '35px' : '40px', color: '#FFF' }} />
              </Fab>
          </Box>
          {showSalesReps.open && (
              <LiveHelpSalesReps
                  open={showSalesReps.open}
                  handleClose={() => {
                    setShowSalesReps({
                      open: false,
                      type: null
                    });
                  }}
                  type={showSalesReps.type}
              />
          )}
      </>
  );
};

LiveHelpButton.propTypes = {
  style: PropTypes.object,
  isMobile: PropTypes.bool,
  type: PropTypes.string
};

export default LiveHelpButton;
